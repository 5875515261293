import React, { useEffect, useRef, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import Alert from './Alert';
import { AppFooter } from '../AppFooter';
import { AppMenu } from '../AppMenu';
import { AppTopbar } from '../AppTopbar';
import AssociationList from './Account/AssociationList';
import BaseCheckPriceList from './Price/Check/BaseCheckPriceList';
import BaseInsurancePriceList from './Price/Insurance/BaseInsurancePriceList';
import BoardList from './Board/BoardList';
import { CSSTransition } from 'react-transition-group';
import ClaimDashboard from './Accident/Dashboard.jsx';
import ClaimList from './Accident/ClaimList.jsx';
import ClaimOldList from './Accident/ClaimOldList.jsx';
import CustomerCompanyList from './Customer/CustomerCompanyList';
import Dashboard from '../components/Dashboard';
import DealerList from './Customer/CustomerList';
import EnterpriseList from './Account/EnterpriseList';
import FAQList from './Board/FAQList';
import LandingPopup from '../components/Common/LandingPopup';
import PartnerList from './Account/PartnerList';
import PaymentList from './Sales/PaymentList';
import PerformanceCheckList from './PerformanceCheck/PerformanceCheckList';
import Post from './Board/Post';
import PrimeReact from 'primereact/api';
import QNA from './../pages/Board/QNA';
import QNAList from './Board/QNAList';
import ReceivableList from './Sales/ReceivableList';
import { ServiceProvider } from '../services';
import ShopCheckPriceList from './Price/Check/ShopCheckPriceList';
import ShopInsurancePriceList from './Price/Insurance/ShopInsurancePriceList';
import ShopList from './Account/ShopList';
import StickyBoard from '../StickyBoard';
import { Tooltip } from 'primereact/tooltip';
import UserList from './Account/UserList';
import _ from 'lodash';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { myInfoSelector } from '../recoil/selectors';
import { useRecoilValueLoadable } from 'recoil';
import SafePaymentList from './Sales/SafePaymentList.jsx';

const boardService = ServiceProvider.board;

const BaseLayout = () => {
  const nodeRef = useRef(null);
  const history = useHistory();
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const claimUser =
    myInfoLoadable?.contents?.roleCode === 'I_ADM' ||
    myInfoLoadable?.contents?.roleCode === 'I_OFF';
  const partner = myInfoLoadable?.contents?.roleCode === 'D_ADM';

  const [layoutMode, setLayoutMode] = useState('static');
  const [layoutColorMode, setLayoutColorMode] = useState('light');
  const [inputStyle, setInputStyle] = useState('outlined');
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);

  const [showLandingPopup, setShowLandingPopup] = useState(true);
  const [popupItem, setPopupItem] = useState(null);
  const [menu, setMenu] = useState([]);
  const copyTooltipRef = useRef();
  const location = useLocation();

  const erpActionService = ServiceProvider.erpAction;
  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === 'overlay') {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === 'static') {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };

  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += ' ' + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          '(^|\\b)' + className.split(' ').join('|') + '(\\b|$)',
          'gi'
        ),
        ' '
      );
  };

  const wrapperClass = classNames('layout-wrapper', {
    'layout-overlay': layoutMode === 'overlay',
    'layout-static': layoutMode === 'static',
    'layout-static-sidebar-inactive':
      staticMenuInactive && layoutMode === 'static',
    'layout-overlay-sidebar-active':
      overlayMenuActive && layoutMode === 'overlay',
    'layout-mobile-sidebar-active': mobileMenuActive,
    'p-input-filled': inputStyle === 'filled',
    'p-ripple-disabled': ripple === false,
    'layout-theme-light': layoutColorMode === 'light',
  });

  function cleanLocalStorage(currentSeq) {
    // local storage 데이터 조회해서 isTodayHidden_ 으로 시작하는 데이터 필터링하고
    const storageDatas = Object.keys(localStorage).filter((field) => {
      return _.startsWith(field, 'isTodayHidden');
    });
    // seq가 다른거 지운다.
    for (let field of storageDatas) {
      const [_, seq] = field.split('_');
      if (Number(seq) !== currentSeq) {
        localStorage.removeItem(`isTodayHidden_${seq}`);
      }
    }
  }

  async function handleLandingPopup() {
    // API 요청을 보내서 현재 띄워줘야할 팝업의 seq 를 확인하고
    // 세션 스토리지 - showPopup_seq
    // 로컬 스토리지 - isTodayHidden_seq
    const today = dayjs();
    const now = today.toDate();

    try {
      const { data } = await boardService.getPopup();
      const {
        boardData: { seq },
      } = data;

      if (seq) {
        const showPopup = sessionStorage.getItem(`showPopup_${seq}`);
        const isTodayHiddenEndTime = localStorage.getItem(
          `isTodayHidden_${seq}`
        );

        if (!showPopup && !isTodayHiddenEndTime) {
          // 세션, 로컬 모두에 저장된 값이 없으면 팝업 오픈하면 됨
          setPopupItem(data);
          setShowLandingPopup(true);

          return;
        }

        if (isTodayHiddenEndTime) {
          if (dayjs(isTodayHiddenEndTime) - now <= 0) {
            // end date 가 만료되었을 때
            // 로컬 스토리지 삭제하고 창 띄우기

            setPopupItem(data);
            setShowLandingPopup(true);

            localStorage.removeItem(`isTodayHidden_${seq}`);

            return;
          }
        }

        cleanLocalStorage(seq);
      }
    } catch (error) {
      // 게시할 팝업이 없으면 아무것도 하지 않고 리턴
      console.log('popup handle 실패', error);
      return;
    }
  }

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, 'body-overflow-hidden');
    } else {
      removeClass(document.body, 'body-overflow-hidden');
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  useEffect(() => {
    (async () => {
      const { data: menus = [], error } = await erpActionService.menus();

      setMenu(menus);
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleLandingPopup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      (partner && history.location.path === undefined) ||
      (partner && history.location.path === '/')
    ) {
      history.push('/compensation/accident/list');
    }
  }, [partner, history]);

  useEffect(() => {
    if (myInfoLoadable.contents) {
      if (myInfoLoadable.contents.roleCode === '6_ADM') {
        history.push('/sales/settlement/safe6');
      }
    }
  }, [myInfoLoadable.contents]);

  return (
    <div className={wrapperClass} onClick={onWrapperClick}>
      {/*공통 툴팁*/}
      <Tooltip
        ref={copyTooltipRef}
        target=".block-action-copy"
        position="bottom"
        content="Copied to clipboard"
        event="focus"
      />

      {/*상단 바*/}
      <AppTopbar
        onToggleMenuClick={onToggleMenuClick}
        layoutColorMode={layoutColorMode}
        mobileTopbarMenuActive={mobileTopbarMenuActive}
        onMobileTopbarMenuClick={onMobileTopbarMenuClick}
        onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
      />

      {/*좌측메뉴*/}
      <div className="layout-sidebar" onClick={onSidebarClick}>
        <AppMenu
          model={menu}
          onMenuItemClick={onMenuItemClick}
          layoutColorMode={layoutColorMode}
        />
        <StickyBoard />
      </div>

      {/*메인 화면*/}
      <div className="layout-main-container">
        <div className="layout-main">
          <Switch>
            <Route
              path="/"
              exact
              component={
                claimUser || partner ? ClaimDashboard : PerformanceCheckList
              }
            />
            <Route exact path="/dashboard" component={Dashboard} />

            <Route
              exact
              path="/performance-check/list"
              component={PerformanceCheckList}
            />
            <Route
              exact
              path="/customer/company/list"
              component={CustomerCompanyList}
            />
            <Route exact path="/customer/dealer/list" component={DealerList} />

            <Route
              exact
              path="/compensation/partner/list"
              component={PartnerList}
            />
            <Route
              exact
              path="/account/association/list"
              component={AssociationList}
            />
            <Route
              exact
              path="/account/enterprise/list"
              component={EnterpriseList}
            />
            <Route exact path="/account/shop/list" component={ShopList} />
            <Route exact path="/account/user/list" component={UserList} />

            <Route
              exact
              path="/price/check/base/list"
              component={BaseCheckPriceList}
            />
            <Route
              exact
              path="/price/insurance/base/list"
              component={BaseInsurancePriceList}
            />
            <Route
              exact
              path="/price/check/shop/list"
              component={ShopCheckPriceList}
            />
            <Route
              exact
              path="/price/insurance/shop/list"
              component={ShopInsurancePriceList}
            />

            <Route exact path="/sales/payment/list" component={PaymentList} />
            <Route
              exact
              path="/sales/settlement/safe6"
              component={SafePaymentList}
            />
            <Route
              exact
              path="/:service/board/notice/list"
              component={BoardList}
            />
            <Route exact path="/:service/board/qna/list" component={QNAList} />
            <Route exact path="/:service/board/faq/list" component={FAQList} />

            <Route exact path="/:service/post/:postId?" component={Post} />
            <Route exact path="/:service/qna/:postId?" component={QNA} />

            <Route
              exact
              path="/compensation/accident/list"
              component={ClaimList}
            />
            <Route
              exact
              path="/compensation/accident/oldlist"
              component={ClaimOldList}
            />
            <Route
              exact
              path="/compensation/accident"
              component={ClaimDashboard}
            />

            <Route
              exact
              path="/sales/receivable/list"
              component={ReceivableList}
            />

            <Route path="*" render={() => <Alert />} />
          </Switch>
        </div>

        {/* Popup */}
        <LandingPopup
          visible={popupItem && showLandingPopup}
          item={popupItem}
          onHide={() => setShowLandingPopup(false)}
        />

        {/*Footer*/}
        <AppFooter layoutColorMode={layoutColorMode} />
      </div>

      <CSSTransition
        nodeRef={nodeRef}
        classNames="layout-mask"
        timeout={{ enter: 200, exit: 200 }}
        in={mobileMenuActive}
        unmountOnExit
      >
        <div ref={nodeRef} className="layout-mask p-component-overlay" />
      </CSSTransition>
    </div>
  );
};

export default BaseLayout;
