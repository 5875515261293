import axios from 'axios';
import AxiosUtil from '../utils/axiosUtil';
import { BaseService } from './BaseService';

const BASE_URI = '/apis/performance-checks';
export const PerformanceCheckService = (function () {
  let instance;
  const OPERATIONS_BASE_URI = BASE_URI + '/operations';
  const baseService = BaseService.getInstance(BASE_URI, 'performanceCheck');

  function init() {
    return {
      ...baseService,
      registerBase: async function (data) {
        return await AxiosUtil.Async.post(BASE_URI + '/data/base', data);
      },
      updateBase: async function (data) {
        return await AxiosUtil.Async.put(BASE_URI + '/data/base', data);
      },
      registerDetail: async function (data, files) {
        return await AxiosUtil.Async.multipart(
          BASE_URI + '/data/detail',
          data,
          files
        );
      },
      registerRecheckBase: async function (data) {
        return await AxiosUtil.Async.post(
          BASE_URI + '/data/recheck/base',
          data
        );
      },
      updateRecheckBase: async function (data) {
        return await AxiosUtil.Async.put(BASE_URI + '/data/recheck/base', data);
      },
      getData: async function (statementNumber) {
        return await AxiosUtil.Async.get(BASE_URI + `/${statementNumber}`);
      },
      getDataMobileByStatementNumber: async function (statementNumber) {
        return await AxiosUtil.Async.get(
          BASE_URI + `/viewer/${statementNumber}`
        );
      },
      getDataMobileByDataId: async function (dataId) {
        return await AxiosUtil.Async.get(BASE_URI + `/viewer/data/${dataId}`);
      },
      getDataMobileByLpns: async function (licensePlateNumber) {
        return await AxiosUtil.Async.get(
          BASE_URI + `/viewer/lpn/${licensePlateNumber}`
        );
      },
      getBase64Url: async function (imgUrl) {
        const encodedImgUrl = encodeURIComponent(imgUrl);
        const queryString = `imageUrl=${encodedImgUrl}`;
        return await AxiosUtil.Async.get(
          '/office/rest/extservice/base64encoding',
          queryString
        );
      },
      delete: async function (statementNumber) {
        return await AxiosUtil.Async.delete(BASE_URI + `/${statementNumber}`);
      },
      list: async function (params) {
        const queryString = Object.entries(params)
          .map((e) => e.join('='))
          .join('&');

        return await AxiosUtil.Async.get(BASE_URI, queryString);
      },
      getDeletedList: async function (params) {
        const queryString = Object.entries(params)
          .map((e) => e.join('='))
          .join('&');

        return await AxiosUtil.Async.get(BASE_URI + '/delete', queryString);
      },
      updatePayment: async function (data, files = null) {
        return await AxiosUtil.Async.updateMultipart(
          OPERATIONS_BASE_URI + '/payment',
          data,
          files
        );
      },
      updateNote: async function (data) {
        return await AxiosUtil.Async.post(OPERATIONS_BASE_URI + '/note', data);
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();

export const PerfomancePayment = {
  getDetail: async (id) => {
    const res = await axios.get(`apis/performance-checks/payment/${id}`);
    return res.data.data;
  },
};

export const performanceReset = {
  deleteInfo: async (licensePlateNumber) => {
    const res = await axios.delete(`${BASE_URI}/info/${licensePlateNumber}`);
    return res.data.data;
  },
};

const BASE_URI_REPAIR = '/apis/repair';
export const RepairApi = {
  getCenter: async (lpns, regDate) => {
    const result = await axios.get(`${BASE_URI_REPAIR}/center`, {
      params: {
        lpns: lpns,
        regDate: regDate,
      },
    });
    return result.data.data;
  },
  getInsurance: async (lpns) => {
    const result = await axios.get(`${BASE_URI_REPAIR}/insurance`, {
      params: {
        lpns: lpns,
      },
    });
    return result.data.data;
  },
};
