import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { CENTER_DOCS, REPAIR_DOCS } from '../../constants/Constants';
import { Checkbox } from 'primereact/checkbox';
import { classNames } from 'primereact/utils';
import { InputTextarea } from 'primereact/inputtextarea';

const SendPush = ({
  onHide,
  postPushMessageDocs,
  showPushType,
  customerInfo,
  pushLoading,
}) => {
  const [missingDocs, setMissingDocs] = useState([]);
  const [checkedState, setCheckedState] = useState({});
  const PUSH_TYPE = showPushType === 'CENTER_DOCS' ? CENTER_DOCS : REPAIR_DOCS;
  const TARGET = showPushType === 'CENTER_DOCS' ? '진단' : '수리';

  const handleDocSelection = (docValue, isChecked) => {
    setCheckedState((prevState) => ({
      ...prevState,
      [docValue]: isChecked,
    }));

    if (isChecked) {
      // 체크되면 missingDocsRepair 배열에 추가
      setMissingDocs((prevDocs) => [...prevDocs, docValue]);
    } else {
      // 체크 해제되면 배열에서 제거
      setMissingDocs((prevDocs) => prevDocs.filter((doc) => doc !== docValue));
    }
  };

  const labels = missingDocs.map((val) => {
    const doc = PUSH_TYPE.find((item) => item.value === val);
    return doc ? doc.label : val;
  });
  const contents = `[미비서류  알림] ${
    customerInfo?.carNbr
  } 차량 | ${labels.join(', ')}`;

  return (
    <Dialog
      onHide={onHide}
      modal
      visible
      style={{ width: '340px' }}
      header={`앱 푸시 전송 - ${TARGET}서류 미비 알림`}
      footer={() => (
        <div className="flex flex-auto align-items-start justify-content-center pt-2">
          <Button
            label="전송하기"
            icon="pi pi-check-circle"
            className=" mr-1"
            onClick={() => {
              if (missingDocs.length === 0) {
                window.cerp.toast.warn('미비 서류 항목을 선택해 주세요.');
                return;
              }
              postPushMessageDocs('P', missingDocs);
            }}
            disabled={!missingDocs.length}
            loading={pushLoading}
          />
        </div>
      )}
    >
      <div>미비서류 선택</div>
      <Divider />
      <div className="mt-1">
        <div className="flex flex-wrap gap-2">
          {PUSH_TYPE.map((doc) => (
            <div key={doc.value} className="ml-1">
              <Checkbox
                inputId={doc.value}
                checked={checkedState[doc.value] || false}
                onChange={(e) => handleDocSelection(doc.value, e.checked)}
                className={classNames({
                  'p-invalid': false,
                })}
              />
              <label className="p-checkbox-label ml-2" htmlFor={doc.value}>
                {doc.label}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-4">
        <InputTextarea
          disabled={true}
          className="w-full mt-1"
          rows={2}
          autoResize
          value={contents}
          readOnly
        />
      </div>
    </Dialog>
  );
};

export default SendPush;
