/* eslint-disable no-unused-vars */
import * as Button from './../../components/Atoms/Button';
import * as Diff from 'deep-object-diff';
import * as Titled from './../../components/Atoms/Titled';

import { Controller, useForm, useWatch } from 'react-hook-form';
import {
  PAYMENT_METHOD,
  VEHICLE_TYPE_LEVEL3,
  YN,
} from '../../constants/Constants';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Badge } from 'primereact/badge';
import { BlockUI } from 'primereact/blockui';
import CheckState from '../../enums/CheckState';
import { CommonUtil } from '../../utils/commonUtil';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MyInfoUtil } from '../../utils/myInfoUtil';
import { PC_BASE_BLUEPRINT } from '../../constants/PerformanceCheckConstants';
import { Panel } from 'primereact/panel';
import { RadioButton } from 'primereact/radiobutton';
import { ServiceProvider } from '../../services';
import UserRoleType from '../../enums/UserRoleType';
import _ from 'lodash';
import { classNames } from 'primereact/utils';
import dayjs from 'dayjs';
import performanceCheckUtil from '../../utils/performanceCheckUtil';
import { TRVL_DSTNC_RANGES } from '../../constants/Constants';

const baseValueItems = _.mapValues(PC_BASE_BLUEPRINT, 'valueItems');

const defaultValues = {
  licensePlateNumber: '',
  association: {},
  enterprise: {},
  shop: {},
  inspector: {},
  customerCompany: {},
  customer: {},
  vehicleInformation: {
    vinRemain: '',
    cnmDetail: '',
    madeAt: 'D',
    VHCTY_ASORT_CODE: '1',
    detailedModelType: 'SA',
    displacement: 0,
    maxPerson: 0,
  },
  vehicleMaintenance: [],
  paymentInformation: {
    ASSRNC_TY_SE_CODE: '2',
    ICNY_CODE: '',
    VHCTY_ASORT_CODE: '1',
    checkPriceOf: '',
    checkPriceTableId: 0,
    checkPriceItemId: '',
    checkPrice: 0,
    checkPriceVAT: 0,
    totalCheckPrice: 0,
    paidCheckPrice: 0,
    checkPricePaidYN: 'N',
    checkPricePaidAt: null,
    checkPricePaidBy: '',
    discountReason: '',
    discountedCheckPrice: 0,
    insurancePriceOf: '',
    insurancePriceTableId: 0,
    insurancePrice: 0,
    insurancePriceVAT: 0,
    totalInsurancePrice: 0,
    paidInsurancePrice: 0,
    insurancePricePaidYN: 'N',
    insurancePricePaidAt: null,
    insurancePricePaidBy: '',
    insurancePriceRefundedAt: null,
    summaryPrice: 0, // 점검료 + 보험료 (VAT 제외)
    vat: 0, // 점검료 VAT + 보험료 VAT
    totalPrice: 0, // ((점검료 + 점검료 VAT) - 할인금액) + (보험료 + 보험료 VAT)
    unpaidPrice: 0,
    paidPrice: 0,
  },
};

const performanceCheckService = ServiceProvider.performanceCheck;
const priceService = ServiceProvider.price;

const CheckBaseDataDialog = ({
  userInfo,
  dataId,
  statementNumber,
  onHide = null,
  visible,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({ defaultValues, reValidateMode: 'onSubmit' });

  const currentValues = useWatch({ control });
  const previousInfo = useRef({
    paymentInformation: null,
    vehicleInformation: null,
  });

  const {
    paymentInformation: {
      totalPrice = 0,
      unpaidPrice = 0,
      paidPrice = 0,
      checkPrice,
      checkPriceVAT,
      totalCheckPrice,
      discountedCheckPrice = 0,
      paidCheckPrice = 0,
      checkPricePaidYN,
      checkPricePaidAt = null,
      checkPricePaidBy = '',
      insurancePrice,
      insurancePriceVAT,
      totalInsurancePrice,
      paidInsurancePrice = 0,
      insurancePricePaidYN,
      insurancePricePaidAt = null,
      insurancePricePaidBy = '',
      insurancePriceRefundedAt = null,
    },
  } = useMemo(() => ({ ...currentValues }), [currentValues]);

  const [codes, setCodes] = useState({});
  const [isRecheck, setIsRecheck] = useState(false);
  const [isModifiable, setIsModifiable] = useState(null);
  const [isTempSave, setIsTempSave] = useState(false);
  const [isInfoChanged, setIsInfoChanged] = useState({
    paymentInformation: false,
    vehicleInformation: false,
  });
  const [performanceCheckData, setPerformanceCheckData] = useState(null);
  const [detailedModelTypeOptions, setDetailedModelTypeOptions] = useState(
    _.filter(VEHICLE_TYPE_LEVEL3, { lvl1: 'D', lvl2: '1' })
  );
  const [note, setNote] = useState('');
  const [loading, setLoading] = useState(false);

  const {
    myAssociationId,
    myEnterpriseId,
    myShopId,
    myUserId,
    myRoleCode,
    // myUserPosition,
  } = useMemo(() => MyInfoUtil.unpack(userInfo), [userInfo]);

  function setDetailedModelType({
    madeAt = _.get(currentValues, 'vehicleInformation.madeAt'),
    assortType = _.get(currentValues, 'vehicleInformation.VHCTY_ASORT_CODE'),
    vehicleInformation = _.get(currentValues, 'vehicleInformation'),
    displace = _.get(currentValues, 'vehicleInformation.displacement'),
    maxPerson = _.get(currentValues, 'vehicleInformation.maxPerson'),
  }) {
    //* filtering 기준
    const filteredOptions = [];
    // 사용할 곳 -> 선택한 보험사의 상품에서 필드값 찾아서 update payment information
    //1. lvl1 : vehicleInformation.madeAt
    //2. lvl2 : vehicleInformation.VHCTY_ASORT_CODE
    const conditions = {
      lvl1: madeAt,
      lvl2: assortType,
    };

    // 2. 전기차나 수소차인 경우에 전기차/수소차 라벨로 책정
    if (
      performanceCheckUtil.Checker.Fuel.isElectronic(vehicleInformation) ||
      performanceCheckUtil.Checker.Fuel.isHydrogen(vehicleInformation)
    ) {
      conditions.label = '전기차/수소차';

      filteredOptions.push(..._.filter(VEHICLE_TYPE_LEVEL3, conditions));
      setDetailedModelTypeOptions(filteredOptions);

      if (filteredOptions[0]) {
        setValue(
          'vehicleInformation.detailedModelType',
          filteredOptions[0].value
        );
      }
      // return;
      return _.get(filteredOptions, '0.value');
    }

    // 3-4. 이륜, 기타의 경우 보험료를 어떻게 책정해야 하는지?
    if (assortType === '5' || assortType === '9') {
      // ---> 보험 상품 이용이 안되는 거면 자가보증으로 바꾸고 보험사 보증 선택할 수 없도록 보험료 업데이트 되지 않도록 픽스 해버리나?
      // 상세 차종은 해당 없음으로 돌려버리고
      // 점검료는 별도로 선택하도록 함
      const option = {
        lvl1: madeAt,
        lvl2: assortType,
        label: '이륜/기타',
        value: '',
      };
      filteredOptions.push(option);
      setDetailedModelTypeOptions(filteredOptions);

      setValue('vehicleInformation.detailedModelType', option.value);
      // return;
      return _.get(filteredOptions, '0.value');
    }

    // 3-3. 화물/특수의 경우 유저가 직접 선택해야 함
    if (assortType === '3' || assortType === '4') {
      filteredOptions.push(..._.filter(VEHICLE_TYPE_LEVEL3, conditions));

      setDetailedModelTypeOptions(filteredOptions);
      setValue(
        'vehicleInformation.detailedModelType',
        filteredOptions[0].value
      );
      // return;
      return _.get(filteredOptions, '0.value');
    }

    // 필터링 완료 후 보여줄 옵션 업데이트
    // 선택이 가능한 경우 상세 차종 값도 즉시 업데이트
    // 선택이 불가능한 경우 보여줄 값 지정하기

    // 1. 국산 혹은 수입
    // 3. 차종 구분 - 승용, 승합 ... 등등

    // 3-1. 차종이 승용인 경우 배기량 입력에 따라 라벨 자동 책정되도록 함
    // 3-2. 승합인 경우 승차인원 입력에 따라 라벨 자동 책정되도록 함
    if (assortType === '1' || assortType === '2') {
      // vehicle list에서 filter 함수에서 참인 것만 뽑아내야함
      const items =
        _.filter(VEHICLE_TYPE_LEVEL3, {
          ...conditions,
        }) || [];
      const option = _.find(items, (item) => {
        return item.filter(displace, maxPerson, vehicleInformation);
      });

      filteredOptions.push(...items);
      setDetailedModelTypeOptions(filteredOptions);

      if (_.get(option, 'value')) {
        setValue(
          'vehicleInformation.detailedModelType',
          _.get(option, 'value')
        );
        return _.get(option, 'value');
      }

      setValue(
        'vehicleInformation.detailedModelType',
        filteredOptions[0].value
      );
      return _.get(filteredOptions, '0.value');
    }
  }

  async function getCheckPriceTable(
    {
      associationId = 0,
      enterpriseId = 0,
      shopId = 0,
      checkPriceOf,
      checkPriceTableId,
    } = {},
    isLocked
  ) {
    let params = { associationId, enterpriseId, shopId };
    if (isLocked) params = { ...params, checkPriceOf, checkPriceTableId };

    const { data: checkPriceTable, error } = await priceService.getCheckPrice(
      params
    );
    // console.log(
    //   '[점검료 조회]',
    //   associationId,
    //   enterpriseId,
    //   shopId,
    //   checkPriceOf,
    //   checkPriceTableId,
    //   checkPriceTable
    // );
    return [checkPriceTable];
  }

  async function getInsurancePriceTables(
    {
      associationId = 0,
      enterpriseId = 0,
      shopId = 0,
      insurancePriceOf,
      insurancePriceTableId,
    } = {},
    isLocked
  ) {
    let params = { associationId, enterpriseId, shopId };
    if (isLocked)
      params = { ...params, insurancePriceOf, insurancePriceTableId };

    const { data: insurancePriceTables, error } =
      await priceService.getInsurancePrices(params);
    const insurances = _.reduce(
      insurancePriceTables,
      (r, v) => {
        r.push({
          label: `[${_.get(v, 'insuranceCompany')}] ${_.get(v, 'companyName')}`,
          value: _.get(v, 'insuranceCompany'),
        });
        return r;
      },
      []
    );
    const insurance = _.get(insurances, 0) || {};

    // console.log(
    //   '[보험료 조회]',
    //   associationId,
    //   enterpriseId,
    //   shopId,
    //   insurancePriceTables,
    //   _.get(insurance, 'value'),
    //   insurance,
    //   insurances
    // );
    return [insurancePriceTables, insurance.value, insurance, insurances];
  }

  async function onDelete(dataId) {
    try {
      const data = await performanceCheckService.delete(dataId);
      if (data) {
        window.cerp.toast.success(
          '성능 점검 삭제 성공',
          `${statementNumber}번 성능 점검을 삭제했습니다. `
        );
        onHide();
      }
    } catch (error) {
      console.log(error);
      window.cerp.dialog.error(
        '성능 점검 삭제 실패',
        `[${error?.code}] ${error?.message}`
      );
    }
  }

  function getCarTypeCode({ madeAt, assortType, detailModel }) {
    return `${madeAt}${assortType}${detailModel}`;
  }

  function clearInsurancePrice() {
    // setValue('paymentInformation.ASSRNC_TY_SE_CODE', '1');
    setValue('paymentInformation.ICNY_CODE', '');
    setValue('paymentInformation.insurancePriceOf', '');
    setValue('paymentInformation.insurancePriceTableId', 0);
    setValue('paymentInformation.insurancePrice', 0);
    setValue('paymentInformation.insurancePriceVAT', 0);
    setValue('paymentInformation.totalInsurancePrice', 0);
    setValue('paymentInformation.insurancePricePaidAt', null);
  }

  function setInsurancePrice({
    distance = _.get(currentValues, 'vehicleInformation.TRVL_DSTNC'),
    warrantyType = _.get(currentValues, 'paymentInformation.ASSRNC_TY_SE_CODE'),
    assortType = _.get(currentValues, 'vehicleInformation.VHCTY_ASORT_CODE'),
    madeAt = _.get(currentValues, 'vehicleInformation.madeAt'),
    detailModel = _.get(currentValues, 'vehicleInformation.detailedModelType'),
    insurancePriceTables = _.get(codes, 'insurancePriceTables'),
  } = {}) {
    const carTypeCode = getCarTypeCode({ madeAt, assortType, detailModel });
    const suffix = performanceCheckUtil.Distance.getInsuranceSuffix(distance);

    console.log(
      '[보험료 테이블 확인]',
      carTypeCode,
      distance,
      suffix,
      `${carTypeCode}_${suffix}`
    );

    /* 주행거리가 기준(20만km) 이상이거나 보증 종류가 자가 보증일 때 */
    if (suffix === 'X') {
      console.log('[보증 대상 아님]');
      clearInsurancePrice();
      // total,
      return [0, 0, 0];
    } else if (assortType === '5' || assortType === '9') {
      // 자가보증이고 이륜이나 기타일때
      const current = dayjs().format('YYYY-MM-DDTHH:mm:ss');

      setValue('paymentInformation.ICNY_CODE', '');
      setValue('paymentInformation.ASSRNC_TY_SE_CODE', '1');
      setValue('paymentInformation.insurancePricePaidAt', current);
      setValue('paymentInformation.insurancePricePaidYn', 'Y');

      return [0, 0, 0];
    } else {
      const insuranceCompany =
        _.get(currentValues, 'paymentInformation.ICNY_CODE') ||
        _.get(_.get(insurancePriceTables, 0), 'insuranceCompany');
      const currentPriceTable = _.find(insurancePriceTables, {
        insuranceCompany,
      });

      console.log(
        '[현재 보험료 테이블]',
        insurancePriceTables,
        insuranceCompany,
        currentPriceTable
      );

      if (currentPriceTable) {
        const { priceTableId, priceTable, insurancePriceOf } =
          currentPriceTable;
        console.log(
          '[보험사보증]',
          insuranceCompany,
          priceTableId,
          insurancePriceOf,
          insurancePriceTables,
          carTypeCode,
          suffix,
          priceTable
        );
        // const originInsurancePrice = isRecheck
        //   ? _.get(currentValues, 'paymentInformation.paidInsurancePriceAmount')
        //   : 0;
        // const originInsurancePriceVAT = isRecheck
        //   ? Math.floor((originInsurancePrice * 0.1) / 10) * 10
        //   : 0;
        // const originTotalInsurancePrice =
        //   originInsurancePrice + originInsurancePriceVAT;

        const originTotalInsurancePrice = isRecheck
          ? _.get(currentValues, 'paymentInformation.paidInsurancePriceAmount')
          : 0;
        const originInsurancePriceVAT = isRecheck
          ? Math.floor((originTotalInsurancePrice * 0.1) / 10) * 10
          : 0;
        const originInsurancePrice =
          originTotalInsurancePrice - originInsurancePriceVAT;

        const currentInsurancePrice =
          _.toNumber(_.get(priceTable, `${carTypeCode}_${suffix}`)) || 0;
        const currentInsurancePriceVAT =
          Math.floor((currentInsurancePrice * 0.1) / 10) * 10;
        const currentTotalInsurancePrice =
          currentInsurancePrice + currentInsurancePriceVAT;

        const isMoreExpensive =
          currentInsurancePrice - originInsurancePrice > 0;

        const insurancePrice = isRecheck
          ? isMoreExpensive
            ? currentInsurancePrice - originInsurancePrice
            : 0
          : currentInsurancePrice;

        const insurancePriceVAT = isRecheck
          ? isMoreExpensive
            ? currentInsurancePriceVAT - originInsurancePriceVAT
            : 0
          : currentInsurancePriceVAT;

        const totalInsurancePrice = isRecheck
          ? isMoreExpensive
            ? currentTotalInsurancePrice - originTotalInsurancePrice
            : 0
          : currentTotalInsurancePrice;

        setValue('paymentInformation.ICNY_CODE', insuranceCompany);
        setValue('paymentInformation.insurancePriceOf', insurancePriceOf);
        setValue('paymentInformation.insurancePriceTableId', priceTableId || 0);
        setValue(
          'paymentInformation.insurancePrice',
          _.isNaN(insurancePrice) ? 0 : insurancePrice
        );
        setValue(
          'paymentInformation.insurancePriceVAT',
          _.isNaN(insurancePriceVAT) ? 0 : insurancePriceVAT
        );

        setValue(
          'paymentInformation.totalInsurancePrice',
          _.isNaN(totalInsurancePrice) ? 0 : totalInsurancePrice
        );

        if (_.isNaN(totalInsurancePrice) || totalInsurancePrice === 0) {
          // 결제할 금액이 없으면 결제완료된 것으로
          const current = dayjs().format('YYYY-MM-DDTHH:mm:ss');
          setValue('paymentInformation.insurancePricePaidAt', current);
          setValue('paymentInformation.insurancePricePaidYN', YN.YES);
        }

        if (isRecheck && totalInsurancePrice > 0) {
          setValue('paymentInformation.insurancePricePaidAt', null);
          setValue('paymentInformation.insurancePricePaidYN', YN.NO);
        }

        return [totalInsurancePrice, insurancePriceVAT, insurancePrice];
      } else {
        // window.cerp.toast.warn('보험료 테이블 오류', '점검장에 등록된 보험료 테이블이 없습니다.');
        clearInsurancePrice();
        return [0, 0, 0];
      }
    }
  }

  function isCheckPricePaid() {
    return checkPricePaidYN === YN.YES;
  }

  function isInsurancePricePaid() {
    return insurancePricePaidYN === YN.YES;
  }

  function checkInfoChanged(key) {
    if (previousInfo.current[key] !== null) {
      const diff = Diff.diff(
        previousInfo.current[key],
        _.get(currentValues, key)
      );
      // console.log(key, diff);
      setIsInfoChanged((ps) => ({
        ...ps,
        [key]: !_.isEmpty(diff),
      }));
    }
  }

  function checkAuth(userInfo, type) {
    const { roleCode } = userInfo;
    // 어드민

    const ACCESS_PERMISSION = {
      DELETE: _.filter(UserRoleType, function (r) {
        return (
          r.value === 'CERP_ADM' ||
          r.value === 'A_MST' ||
          r.value === 'E_OFF' ||
          _.startsWith(r.value, 'S_')
        );
      }),
      UPDATE: _.filter(UserRoleType, function (r) {
        return (
          r.value === 'CERP_ADM' ||
          r.value === 'A_MST' ||
          r.value === 'A_ADM' ||
          r.value === 'C_OFF' ||
          _.startsWith(r.value, 'S_')
        );
      }),
    };

    return _.findIndex(ACCESS_PERMISSION[type], { value: roleCode }) >= 0;
  }

  //ANCHOR - 성능점검 정보 가져오기
  useEffect(() => {
    (async () => {
      setLoading(true);

      if (dataId) {
        try {
          const { data } = await performanceCheckService.getData(dataId);

          const { isLocked, performanceCheckData, performanceCheckPhotos } =
            data;
          const {
            // dataId,
            statementNumber,
            originDataId,
            isRecheck,
            originStatementNumber,
            licensePlateNumber,
            checkState,
            association: associationRaw,
            enterprise: enterpriseRaw,
            shop: shopRaw,
            inspector: inspectorRaw,
            customerCompany: customerCompanyRaw,
            customer: customerRaw,
            vehicleInformation: vehicleInformationRaw,
            vehicleMaintenance: vehicleMaintenanceRaw,
            paymentInformation: paymentInformationRaw,
            note,
          } = performanceCheckData;

          setIsModifiable(isLocked);
          setIsRecheck(isRecheck === YN.YES);
          setIsTempSave(checkState === CheckState.Temp);

          const association = JSON.parse(associationRaw);
          const enterprise = JSON.parse(enterpriseRaw);
          const shop = JSON.parse(shopRaw);
          const inspector = JSON.parse(inspectorRaw);
          const customerCompany = JSON.parse(customerCompanyRaw);
          const customer = JSON.parse(customerRaw);
          const vehicleInformation = JSON.parse(vehicleInformationRaw);
          const vehicleMaintenance = JSON.parse(vehicleMaintenanceRaw);
          const paymentInformation = JSON.parse(paymentInformationRaw);

          const options = _.filter(VEHICLE_TYPE_LEVEL3, {
            lvl1: _.get(vehicleInformation, 'madeAt'),
            lvl2: _.get(vehicleInformation, 'VHCTY_ASORT_CODE'),
          });
          setDetailedModelTypeOptions(options);

          const { associationId, enterpriseId, shopId, userId } = inspector;

          if (associationId && enterpriseId && shopId) {
            const [
              [checkPriceTable],
              [
                insurancePriceTables,
                insuranceCompany,
                insurancePriceTable,
                insurances,
              ],
            ] = await Promise.all([
              getCheckPriceTable(
                {
                  associationId,
                  enterpriseId,
                  shopId,
                },
                isLocked
              ),

              getInsurancePriceTables(
                {
                  associationId,
                  enterpriseId,
                  shopId,
                },
                isLocked
              ),
            ]);

            setCodes((ps) => ({
              ...ps,
              insurancePriceTables,
              insurances,
              checkPriceTable,
            }));
          }

          previousInfo.current = {
            paymentInformation,
            vehicleInformation,
          };

          setPerformanceCheckData({
            dataId,
            statementNumber,
            licensePlateNumber,
            association,
            enterprise,
            shop,
            inspector,
            customerCompany,
            customer,
            vehicleInformation: {
              ...vehicleInformation,
              detailedModelType:
                _.get(vehicleInformation, 'detailedModelType') ||
                setDetailedModelType({
                  madeAt: _.get(vehicleInformation, 'madeAt'),
                  assortType: _.get(vehicleInformation, 'VHCTY_ASORT_CODE'),
                  vehicleInformation: vehicleInformation,
                  displace: _.get(vehicleInformation, 'displacement'),
                  maxPerson: _.get(vehicleInformation, 'maxPerson'),
                }),
            },
            vehicleMaintenance,
            paymentInformation,
          });

          setNote(note);
        } catch (error) {
          console.log('error', error);
          window.cerp.dialog.error(
            '성능점검 기본정보 조회 실패',
            `[${error?.code}] ${error?.message}`
          );
        }
      }

      setLoading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    statementNumber,
    myAssociationId,
    myEnterpriseId,
    myShopId,
    myUserId,
    myRoleCode,
    dataId,
  ]);

  useEffect(() => {
    if (performanceCheckData !== null) {
      reset(performanceCheckData);
    }
  }, [performanceCheckData, reset]);

  useEffect(() => {
    checkInfoChanged('vehicleInformation');
    checkInfoChanged('paymentInformation');
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValues]);

  const getRangeLabel = (num) => {
    for (let range of TRVL_DSTNC_RANGES) {
      if (num <= range.max) {
        return range.label;
      }
    }
    return '20만 이상';
  };

  return (
    <Dialog
      modal
      visible={visible}
      maximizable
      breakpoints={{ '960px': '90vw' }}
      header={`${isRecheck ? '재' : ''}성능점검 ${
        statementNumber === '' ? '등록' : '수정'
      }`}
      style={{ width: '76vw' }}
      contentClassName="cm-dialog-content"
      onHide={onHide}
      footer={
        <div className="flex flex-auto align-items-start justify-content-start pt-4">
          <div className="flex flex-auto flex-wrap align-items-center justify-content-start gap-1">
            <Button.Default
              type="button"
              label="닫기"
              className="p-button-outlined"
              onClick={onHide}
            />
            {checkAuth(userInfo, 'DELETE') && (
              <Button.Delete
                type="button"
                onDelete={async () => {
                  await onDelete(currentValues.dataId);
                }}
                disabled={isModifiable}
              />
            )}
          </div>
          <div className="flex flex-auto flex-wrap align-items-center justify-content-end gap-1">
            <>
              {dataId !== '' && (
                <Button.Default
                  type="button"
                  label="성능점검수정"
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  className="p-button-outlined"
                  onClick={() => {
                    onHide();
                    CommonUtil.Window.popup(
                      `/performance-check/form/${statementNumber}-${dataId}`
                    );
                  }}
                  // disabled={isModifiable}
                />
              )}
            </>
          </div>
        </div>
      }
    >
      <BlockUI
        template={
          loading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
        blocked={loading}
      >
        <form>
          <div className="grid">
            <div className="col-12 mb-2 lg:mb-0 py-0">
              <Panel header="0. 성능점검 정보">
                <table
                  className="w-full h-full border-1 border-gray-300 border-round-sm"
                  cellPadding="0"
                  cellSpacing="0"
                >
                  <thead />
                  <tbody>
                    <tr className="border-1 border-gray-300">
                      <th className="px-2 py-1 border-gray-300 border-bottom-0 bg-gray-100">
                        성능지 번호
                      </th>
                      <td className="px-2 border-gray-300 border-bottom-0">
                        {currentValues.statementNumber}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Panel>
            </div>
            <div className="col-12 mb-2 lg:col-6 lg:mb-0 py-0 mt-2">
              <Panel header="1. 성능점검자 정보">
                <div className="grid mb-0">
                  <div className="col-12">
                    <table
                      className="w-full h-full border-1 border-gray-300 border-round-sm"
                      cellPadding="0"
                      cellSpacing="0"
                    >
                      <thead />
                      <tbody>
                        <tr className="border-1 border-gray-300">
                          <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100">
                            협회
                          </th>
                          <td className="px-2 border-gray-300 border-bottom-1">
                            [{currentValues.inspector.associationCode}]{' '}
                            {currentValues.inspector.associationName}
                          </td>
                        </tr>
                        <tr className="border-1 border-gray-300">
                          <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100">
                            점검업체
                          </th>
                          <td className="px-2 border-gray-300 border-bottom-1">
                            [{currentValues.inspector.enterpriseCode}]{' '}
                            {currentValues.inspector.enterpriseName}
                          </td>
                        </tr>
                        <tr className="border-1 border-gray-300">
                          <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100">
                            점검장
                          </th>
                          <td className="px-2 border-gray-300 border-bottom-1">
                            [{currentValues.inspector.shopCode}]{' '}
                            {currentValues.inspector.shopName}
                          </td>
                        </tr>
                        <tr className="border-1 border-gray-300">
                          <th className="px-2 py-1 border-gray-300 border-bottom-0 bg-gray-100">
                            점검자
                          </th>
                          <td className="px-2">
                            {currentValues.inspector.userName}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Panel>
            </div>

            <div className="col-12 lg:col-6 lg:mb-0 py-0 mt-2">
              <Panel header="2. 고객 정보">
                <div className="grid mb-0">
                  <div className="col-12">
                    <table
                      className="w-full h-full border-1 border-gray-300 border-round-sm"
                      cellPadding="0"
                      cellSpacing="0"
                    >
                      <thead />
                      <tbody>
                        <tr className="border-1 border-gray-300">
                          <th className="bg-gray-100 px-2 py-1 border-gray-300 border-bottom-1">
                            상사정보
                          </th>
                          <td className="px-2 border-gray-300 border-bottom-1">
                            {currentValues.customerCompany.companyName} (
                            {
                              currentValues.customerCompany
                                .businessRegistrationNumber
                            }
                            )
                          </td>
                        </tr>
                        <tr className="border-1 border-gray-300">
                          <th className="bg-gray-100 px-2 py-1">고객정보</th>
                          <td className="px-2">
                            {currentValues.customer.customerName} |{' '}
                            {currentValues.customer.contact}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Panel>
            </div>
          </div>

          <div className="grid mt-2">
            <div className="col-12 mb-2 lg:col-6 lg:mb-0 py-0">
              <Panel header="3. 차량 정보">
                <div className="grid mb-0">
                  <div className="col-12">
                    <div className="field">
                      <label>차량번호</label>
                      <Controller
                        control={control}
                        name="licensePlateNumber"
                        defaultValue=""
                        render={({ field, fieldState, formState }) => (
                          <div className="p-inputgroup">
                            <InputText
                              id={field.name}
                              placeholder="차량번호"
                              {...field}
                              onFocus={(e) => e.target.select()}
                              readOnly
                              className="bg-gray-100"
                            />
                          </div>
                        )}
                      />
                    </div>

                    <Panel header="차량상세정보" className="cm-inner-panel">
                      <div className="grid">
                        <div className="col-12">
                          <Controller
                            control={control}
                            name="vehicleInformation.CNM"
                            defaultValue=""
                            render={({ field, fieldState, formState }) => (
                              <Titled.InputText
                                title="차명"
                                {...field}
                                readOnly
                              />
                            )}
                          />
                        </div>
                        <div className="col-12 lg:col-6 mb-0">
                          <Controller
                            control={control}
                            name="vehicleInformation.cnmDetail"
                            defaultValue=""
                            render={({ field, fieldState, formState }) => (
                              <Titled.InputText
                                title="세부모델"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                }}
                                selectOnFocus
                                readOnly
                                className="bg-gray-100"
                              />
                            )}
                          />
                        </div>
                        <div className="col-12 lg:col-6 mb-0">
                          <Controller
                            control={control}
                            name="vehicleInformation.PRYE"
                            defaultValue=""
                            render={({ field, fieldState, formState }) => (
                              <Titled.InputNumber
                                title="차량연식"
                                {...field}
                                readOnly
                                useGrouping={false}
                                suffix="년식"
                              />
                            )}
                          />
                        </div>
                        <div className="col-12 lg:col-6 mb-0">
                          <div
                            className="field m-0"
                            style={{ position: 'relative' }}
                          >
                            <label>주행거리</label>
                            <Controller
                              control={control}
                              name="vehicleInformation.TRVL_DSTNC"
                              defaultValue={0}
                              render={({ field, fieldState, formState }) => (
                                <InputNumber
                                  id={field.name}
                                  {..._.omit(field, ['onChange'])}
                                  inputRef={field.ref}
                                  className="w-full"
                                  inputClassName="w-full text-right bg-gray-100"
                                  readOnly
                                  suffix=" Km"
                                  inputmode="numeric"
                                  onFocus={(e) => e.target.select()}
                                />
                              )}
                            />
                            {currentValues.vehicleInformation.TRVL_DSTNC !==
                              undefined &&
                              currentValues.vehicleInformation.TRVL_DSTNC !==
                                null &&
                              currentValues.vehicleInformation.TRVL_DSTNC !==
                                0 && (
                                <Badge
                                  severity={
                                    getRangeLabel(
                                      currentValues.vehicleInformation
                                        .TRVL_DSTNC
                                    )?.includes('이하')
                                      ? 'info'
                                      : 'danger'
                                  }
                                  value={getRangeLabel(
                                    currentValues.vehicleInformation.TRVL_DSTNC
                                  )}
                                  style={{
                                    fontSize: '0.9rem',
                                    position: 'absolute',
                                    top: '0',
                                    right: '0',
                                    zIndex: 1,
                                  }}
                                />
                              )}
                          </div>
                        </div>
                        <div className="col-12 lg:col-6 mb-0">
                          <div className="field m-0">
                            <label>
                              <i className="text-red-400 pi mr-1" />
                              차대번호
                            </label>
                            <div className="p-inputgroup">
                              <div className="p-inputgroup-addon">
                                {`${_.get(
                                  currentValues,
                                  'vehicleInformation.VIN'
                                )}`}
                              </div>
                              <Controller
                                control={control}
                                name="vehicleInformation.vinRemain"
                                render={({ field, fieldState, formState }) => (
                                  <InputText
                                    {...field}
                                    placeholder="6자리"
                                    minLength="6"
                                    maxLength="6"
                                    onChange={(e) => {
                                      field.onChange(e);
                                    }}
                                    onFocus={(e) => e.target.select()}
                                    className={classNames('bg-gray-100', {
                                      'p-invalid': errors[field.name],
                                    })}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 lg:col-6 mb-0">
                          <div className="field m-0">
                            <label>최초등록일</label>
                            <div className="p-inputgroup">
                              <Controller
                                control={control}
                                name="vehicleInformation.FRST_REGIST_DE"
                                defaultValue=""
                                render={({ field, fieldState, formState }) => (
                                  <InputMask
                                    {...field}
                                    className="bg-gray-100"
                                    mask="9999-99-99"
                                    slotChar="yyyy-mm-dd"
                                    readOnly
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 lg:col-6 mb-0">
                          <div className="field m-0">
                            <label>검사유효기간</label>
                            <div className="p-inputgroup">
                              <Controller
                                control={control}
                                name="vehicleInformation.INSPT_VALID_PD_BGNDE"
                                defaultValue=""
                                rules={{
                                  required: '날짜를 입력하세요.',
                                  validate: (v) =>
                                    dayjs(v, 'YYYY-MM-DD', true).isValid() ||
                                    '유효하지 않은 날짜입니다.',
                                }}
                                render={({ field, fieldState, formState }) => (
                                  <InputMask
                                    {...field}
                                    mask="9999-99-99"
                                    slotChar="yyyy-mm-dd"
                                    readOnly
                                    onFocus={(e) => e.target.select()}
                                    className="bg-gray-100"
                                  />
                                )}
                              />
                              <span className="p-inputgroup-addon w-min">
                                {' '}
                                ~{' '}
                              </span>
                              <Controller
                                control={control}
                                name="vehicleInformation.INSPT_VALID_PD_ENDDE"
                                defaultValue=""
                                rules={{
                                  required: '날짜를 입력하세요.',
                                  validate: (v) =>
                                    dayjs(v, 'YYYY-MM-DD', true).isValid() ||
                                    '유효하지 않은 날짜입니다.',
                                }}
                                render={({ field, fieldState, formState }) => (
                                  <InputMask
                                    {...field}
                                    mask="9999-99-99"
                                    slotChar="yyyy-mm-dd"
                                    readOnly
                                    onFocus={(e) => e.target.select()}
                                    className="bg-gray-100"
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 lg:col-6 mb-0">
                          <Controller
                            control={control}
                            name="vehicleInformation.MTRS_FOM_NM"
                            defaultValue=""
                            render={({ field, fieldState, formState }) => (
                              <Titled.InputText
                                title="원동기형식"
                                {...field}
                                readOnly
                              />
                            )}
                          />
                        </div>
                        <div className="col-12 lg:col-3 mb-0">
                          <Controller
                            control={control}
                            name="vehicleInformation.STMD_AT"
                            defaultValue=""
                            render={({ field, fieldState, formState }) => (
                              <Titled.InputText
                                title="튜닝이력"
                                {...field}
                                value={field.value === YN.NO ? '없음' : '있음'}
                                readOnly
                              />
                            )}
                          />
                        </div>
                        <div className="col-12 lg:col-3 mb-0">
                          <Controller
                            control={control}
                            name="vehicleInformation.PRPOS_CHANGE_SE_AT"
                            defaultValue=""
                            render={({ field, fieldState, formState }) => (
                              <Titled.InputText
                                title="용도변경이력"
                                {...field}
                                value={field.value === YN.NO ? '없음' : '있음'}
                                readOnly
                              />
                            )}
                          />
                        </div>
                        <div className="col-12 lg:col-6 mb-0">
                          <div className="field m-0">
                            <label>차종구분</label>
                            <div className="flex flex-row align-content-center justify-content-evenly gap-1">
                              <Controller
                                control={control}
                                name="vehicleInformation.madeAt"
                                defaultValue="D"
                                render={({ field, fieldState, formState }) => (
                                  <Titled.InputText
                                    {...field}
                                    value={
                                      field.value === 'D' ? '국산' : '수입'
                                    }
                                    readOnly
                                  />
                                )}
                              />
                              <Controller
                                control={control}
                                name="vehicleInformation.VHCTY_ASORT_CODE"
                                defaultValue="1"
                                render={({ field, fieldState, formState }) => (
                                  <Titled.InputText
                                    {...field}
                                    value={
                                      baseValueItems.VHCTY_ASORT_CODE.find(
                                        (el) => el.value === field.value
                                      )?.label
                                    }
                                    readOnly
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 lg:col-6 mb-0">
                          <Controller
                            control={control}
                            name="vehicleInformation.detailedModelType"
                            defaultValue="SA"
                            render={({ field, fieldState, formState }) => (
                              <Titled.InputText
                                title="차종상세구분"
                                {...field}
                                value={
                                  detailedModelTypeOptions.find(
                                    (el) => el.value === field.value
                                  )?.label || ''
                                }
                                readOnly
                              />
                            )}
                          />
                        </div>
                        <div className="col-12 lg:col-6 mb-0">
                          <Controller
                            control={control}
                            name="vehicleInformation.GRBX_KND_CODE"
                            defaultValue=""
                            render={({ field, fieldState, formState }) => (
                              <Titled.InputText
                                title="변속기"
                                {...field}
                                value={
                                  baseValueItems.GRBX_KND_CODE?.find(
                                    (el) => el.value === field.value
                                  )?.label || ''
                                }
                                readOnly
                              />
                            )}
                          />
                        </div>
                        <div className="col-12 lg:col-6 mb-0">
                          <Controller
                            control={control}
                            name="vehicleInformation.USE_FUEL_CODE"
                            defaultValue=""
                            render={({ field, fieldState, formState }) => (
                              <Titled.InputText
                                title="연료구분"
                                {...field}
                                value={
                                  baseValueItems.USE_FUEL_CODE?.find(
                                    (el) => el.value === field.value
                                  )?.label || ''
                                }
                                readOnly
                              />
                            )}
                          />
                        </div>
                        <div className="col-12 lg:col-6 mb-0">
                          <Controller
                            control={control}
                            name="vehicleInformation.displacement"
                            defaultValue={0}
                            render={({ field, fieldState, formState }) => (
                              <Titled.InputNumber
                                id={field.name}
                                {...field}
                                title="배기량"
                                suffix="cc"
                                selectOnFocus
                                readOnly
                                className="bg-gray-100"
                                error={_.get(errors, field.name)}
                              />
                            )}
                          />
                        </div>
                        <div className="col-12 lg:col-6 mb-0">
                          <Controller
                            control={control}
                            name="vehicleInformation.maxPerson"
                            defaultValue={0}
                            render={({ field, fieldState, formState }) => (
                              <Titled.InputNumber
                                id={field.name}
                                {...field}
                                title="승차인원"
                                suffix="명 이하"
                                selectOnFocus
                                readOnly
                                className="bg-gray-100"
                                min={0}
                                max={99}
                                error={_.get(errors, field.name)}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </Panel>
                  </div>
                </div>
              </Panel>
            </div>

            <div className="col-12 mb-2 lg:col-6 lg:mb-0 py-0">
              <Panel header="4. 결제 정보">
                <div className="grid">
                  <div className="col-12 mb-0">
                    <div className="field mb-0">
                      <label>보증방식</label>
                      <Controller
                        control={control}
                        name="paymentInformation.ASSRNC_TY_SE_CODE"
                        defaultValue="2"
                        render={({ field }) => (
                          <div className="bg-transparent flex flex-auto flex-wrap align-items-center justify-content-start gap-3">
                            {baseValueItems['ASSRNC_TY_SE_CODE'].map(
                              (item, idx) => (
                                <div key={`${field.name}_${idx}`}>
                                  <div className="flex flex-auto align-items-center justify-content-start gap-1">
                                    <RadioButton
                                      inputId={`trb_${field.name}_${idx}`}
                                      name={field.name}
                                      value={item.value}
                                      checked={field.value === item.value}
                                      disabled
                                    />
                                    <label
                                      className={item.labelColor}
                                      htmlFor={`trb_${field.name}_${idx}`}
                                    >
                                      {item.label}
                                    </label>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-12 lg:col-5 mb-0">
                    <Controller
                      control={control}
                      name="paymentInformation.ICNY_CODE"
                      defaultValue=""
                      render={({ field }) => (
                        <Titled.InputText
                          title="보증업체"
                          {...field}
                          value={
                            !!currentValues.paymentInformation?.ICNY_CODE
                              ? codes?.insurances?.find(
                                  (el) =>
                                    el.value ===
                                    currentValues.paymentInformation?.ICNY_CODE
                                )?.label
                              : '없음'
                          }
                          readOnly
                        />
                      )}
                    />
                  </div>
                  <div className="col-12 lg:col-7 mb-0">
                    <div className="field m-0">
                      <label>보험료</label>
                      <div className="flex flex-row align-content-center justify-content-evenly gap-1">
                        <Controller
                          control={control}
                          name="paymentInformation.insurancePrice"
                          defaultValue={0}
                          render={({ field }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className="w-full"
                              inputClassName={classNames('w-full text-right', {
                                'bg-yellow-200': !isInsurancePricePaid(),
                                'bg-green-200': isInsurancePricePaid(),
                              })}
                              value={
                                currentValues.paymentInformation
                                  ?.insurancePrice +
                                currentValues.paymentInformation
                                  ?.insurancePriceVAT
                              }
                              readOnly
                              suffix=" 원"
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name="paymentInformation.insurancePricePaidBy"
                          defaultValue={''}
                          render={({ field }) => (
                            <Dropdown
                              className="w-full bg-gray-100"
                              {...field}
                              disabled
                              readOnly
                              options={PAYMENT_METHOD}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  {isRecheck && (
                    <div className="col-12">
                      <Controller
                        control={control}
                        name="paymentInformation.originTotalInsurancePrice"
                        defaultValue={0}
                        render={({ field }) => (
                          <Titled.InputNumber
                            id={field.name}
                            title="기존 보험료"
                            {...field}
                            readOnly
                            suffix=" 원"
                          />
                        )}
                      />
                    </div>
                  )}
                  <div className="col-12 mb-1 lg:col-5 lg:mb-0">
                    <Controller
                      control={control}
                      name="paymentInformation.checkPriceItemId"
                      defaultValue=""
                      render={({ field }) => (
                        <Titled.Dropdown
                          id={field.name}
                          {...field}
                          title="점검료구분"
                          className="bg-gray-100"
                          disabled
                          options={_.get(
                            codes,
                            'checkPriceTable.checkPriceItems'
                          )}
                          placeholder="없음"
                          optionLabel="title"
                          optionValue="id"
                          error={_.get(errors, field.name)}
                        />
                      )}
                    />
                  </div>
                  <div className="col-12 lg:col-7 mb-0">
                    <div className="field m-0">
                      <label>점검료</label>
                      <div className="flex flex-row gap-1">
                        <Controller
                          control={control}
                          name="paymentInformation.totalCheckPrice"
                          defaultValue={0}
                          render={({ field }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className="w-full"
                              inputClassName={classNames('w-full text-right', {
                                'bg-yellow-200': !isCheckPricePaid(),
                                'bg-green-200': isCheckPricePaid(),
                              })}
                              value={
                                currentValues.paymentInformation?.checkPrice +
                                currentValues.paymentInformation
                                  ?.checkPriceVAT -
                                currentValues.paymentInformation
                                  ?.discountedCheckPrice
                              }
                              readOnly
                              suffix=" 원"
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name="paymentInformation.checkPricePaidBy"
                          defaultValue={''}
                          render={({ field }) => (
                            <Dropdown
                              className="w-full bg-gray-100"
                              {...field}
                              disabled
                              readOnly
                              options={PAYMENT_METHOD}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 lg:col-4 mb-0">
                    <Controller
                      control={control}
                      name="paymentInformation.summaryPrice"
                      defaultValue={0}
                      render={({ field, fieldState, formState }) => (
                        <Titled.InputNumber
                          id={field.name}
                          {...field}
                          title="합계 (VAT 제외)"
                          suffix="원"
                          readOnly
                          value={
                            currentValues.paymentInformation?.checkPrice +
                            currentValues.paymentInformation?.insurancePrice -
                            currentValues.paymentInformation
                              ?.discountedCheckPrice
                          }
                        />
                      )}
                    />
                  </div>
                  <div className="col-12 lg:col-4 mb-0">
                    <Controller
                      control={control}
                      name="paymentInformation.vat"
                      defaultValue={0}
                      render={({ field, fieldState, formState }) => (
                        <Titled.InputNumber
                          id={field.name}
                          {...field}
                          title="VAT"
                          suffix="원"
                          readOnly
                          value={
                            currentValues.paymentInformation?.checkPriceVAT +
                            currentValues.paymentInformation?.insurancePriceVAT
                          }
                        />
                      )}
                    />
                  </div>
                  <div className="col-12 lg:col-4 mb-0">
                    <div className="field m-0">
                      <label>점검료할인금액</label>
                      <div className="p-inputgroup">
                        <Controller
                          control={control}
                          name="paymentInformation.discountedCheckPrice"
                          defaultValue={0}
                          render={({ field, fieldState, formState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              inputClassName="text-right bg-gray-100"
                              suffix=" 원"
                              min={0}
                              max={_.get(
                                currentValues,
                                'paymentInformation.totalCheckPrice'
                              )}
                              inputmode="numeric"
                              onFocus={(e) => e.target.select()}
                              readOnly
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mb-0">
                    <Controller
                      control={control}
                      name="paymentInformation.discountReason"
                      defaultValue=""
                      render={({ field, fieldState, formState }) => (
                        <Titled.InputText
                          id={field.name}
                          {...field}
                          title="할인사유"
                          readOnly
                          className="bg-gray-100"
                        />
                      )}
                    />
                  </div>

                  <div className="col-12 mb-2">
                    <div className="field m-0">
                      <Controller
                        control={control}
                        name={'paymentInformation.totalPrice'}
                        defaultValue={0}
                        rules={{
                          required: '필수 입력항목입니다.',
                        }}
                        render={({ field, fieldState, formState }) => (
                          <Titled.InputNumber
                            id={field.name}
                            {...field}
                            title={'결제금액(VAT 포함)'}
                            className={classNames('font-bold', {
                              'bg-yellow-200': !(
                                isCheckPricePaid() && isInsurancePricePaid()
                              ),
                              'bg-green-200':
                                isCheckPricePaid() && isInsurancePricePaid(),
                            })}
                            readOnly
                            suffix=" 원"
                            value={
                              currentValues.paymentInformation?.checkPrice +
                              currentValues.paymentInformation?.checkPriceVAT +
                              currentValues.paymentInformation?.insurancePrice +
                              currentValues.paymentInformation
                                ?.insurancePriceVAT -
                              currentValues.paymentInformation
                                ?.discountedCheckPrice
                            }
                          />
                        )}
                      />
                    </div>
                  </div>
                  {/* <div className="col-12 lg:col-6 mb-0">
                    <Controller
                      control={control}
                      name="paymentInformation.paidPrice"
                      defaultValue={0}
                      rules={{
                        required: '필수 입력항목입니다.',
                      }}
                      render={({ field, fieldState, formState }) => (
                        <Titled.InputNumber
                          id={field.name}
                          {...field}
                          title="기결제금액"
                          suffix="원"
                          readOnly
                          className={classNames('text-right', {
                            'font-bold bg-green-200':
                              isCheckPricePaid() && isInsurancePricePaid(),
                          })}
                          value={paidCPrice + paidIprice}
                        />
                      )}
                    />
                  </div>
                  <div className="col-12 lg:col-6 mb-0">
                    <Controller
                      control={control}
                      name="paymentInformation.unpaidPrice"
                      defaultValue={0}
                      rules={{
                        required: '필수 입력항목입니다.',
                      }}
                      render={({ field, fieldState, formState }) => (
                        <Titled.InputNumber
                          id={field.name}
                          {...field}
                          title="미결제잔액"
                          suffix="원"
                          readOnly
                          className={classNames('text-right', {
                            'font-bold bg-yellow-200': !(
                              isCheckPricePaid() && isInsurancePricePaid()
                            ),
                          })}
                          value={totalCIPrice - paidCPrice - paidIprice}
                        />
                      )}
                    />
                  </div> */}
                </div>
              </Panel>

              <Panel header="비고사항" className="mt-2">
                <InputTextarea
                  className="w-full mb-1"
                  rows={8}
                  autoResize
                  value={note || ''}
                  onChange={({ target: { value } }) => setNote(value)}
                  disabled={_.isEmpty(performanceCheckData)}
                />
                <div className="w-full p-inputgroup">
                  <Button.Default
                    type="button"
                    className="w-full p-button-outlined"
                    label="비고사항수정"
                    icon="pi pi-save"
                    onClick={async () => {
                      const { dataId, statementNumber } = currentValues;
                      const { data, error } =
                        await performanceCheckService.updateNote({
                          dataId,
                          statementNumber,
                          note,
                        });

                      if (data) {
                        const {
                          performanceCheckData: { note: updatedNote },
                        } = data;
                        setNote(updatedNote);
                        window.cerp.toast.success(
                          `비고사항 수정 완료`,
                          `비고사항이 수정되었습니다.`
                        );
                      }

                      if (error)
                        window.cerp.dialog.error(
                          '비고사항 수정 실패',
                          `[${error?.code}] ${error?.message}`
                        );
                    }}
                    disabled={
                      _.isEmpty(performanceCheckData) || _.isEmpty(note)
                    }
                  />
                </div>
              </Panel>
            </div>
          </div>
        </form>
      </BlockUI>
    </Dialog>
  );
};

export default CheckBaseDataDialog;
