import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { Calendar } from 'primereact/calendar';
import * as Button from '../../components/Atoms/Button/index.js';
import * as Modal from './../../components/Atoms/Modal';
import { compressImages } from '../../utils/compressUtil';
import filesize from 'filesize';
import { Badge } from 'primereact/badge';
import { Image } from 'primereact/image';
import FileState from '../../enums/FileState';

import _ from 'lodash';
import dayjs from 'dayjs';
import { COUNT_PER_PAGE } from '../../constants/Constants.js';
import { MyInfoUtil } from '../../utils/myInfoUtil.js';
import SearchMenu from '../../components/Menu/SearchMenu.js';
import { myInfoSelector } from '../../recoil/selectors.js';
import SafePaymentDetail from './SafePaymentDetail.jsx';
import Settlement from '../../services/SettlementService.js';
import { ColumnGroup } from 'primereact/columngroup';
import AxiosUtil from '../../utils/axiosUtil.js';
import { useDropzone } from 'react-dropzone';
import { BlockUI } from 'primereact/blockui';
import { Panel } from 'primereact/panel';
import { CommonUtil } from '../../utils/commonUtil';
import { Dropdown } from 'primereact/dropdown';
import SendSMS from '../Accident/SendSMS.jsx';
import { Claim } from '../../services/ClaimService.js';
import { ServiceProvider } from '../../services';

const priceFormatter = new Intl.NumberFormat('ko-KR', { style: 'decimal' });
const customerCompanyService = ServiceProvider.customerCompany;

const DEFAULT_SEARCH_CONDITIONS = {
  settlementMonth: '',
  customerCompanyName: '',
  range: 'month',
};

const DEFAULT_LAZY_PARAMS = {
  first: 0,
  rows: 30,
  page: 0,
};

const SafePaymentList = () => {
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const {
    myAssociationId,
    myEnterpriseId,
    myShopId,
    myUserId,
    myRoleCode,
    myUserPosition,
    myShopMainPhone,
  } = useMemo(
    () => MyInfoUtil.unpack(myInfoLoadable.contents),
    [myInfoLoadable.contents]
  );

  const defaultMonth = dayjs('202407', 'YYYYMM');
  const defaultMonthDate = defaultMonth.toDate();
  const today = dayjs().toDate();
  const [searchConditions, setSearchConditions] = useState({
    ...DEFAULT_SEARCH_CONDITIONS,
  });
  const [lazyParams, setLazyParams] = useState({ ...DEFAULT_LAZY_PARAMS });
  const [showDetail, setShowDetail] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [curDay, setCurDay] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [sumData, setSumData] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [date, setDate] = useState(defaultMonthDate);
  const [searchMode, setSearchMode] = useState(null);
  const [fileItems, setFileItems] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [showModal, setShowModal] = useState({
    fileUpload: false,
    photoUpload: false,
    detail: false,
  });
  const [fileDate, setFileDate] = useState(today);

  const exportToExcel = async (type) => {
    setExporting(true);
    const params = {
      settleMonth: dayjs(date).format('YYYYMM'),
    };
    await AxiosUtil.exportToExcel(`settlement/${type}`, params);
    setExporting(false);
  };

  const uploadExcelData = async () => {
    setUploadLoading(true);
    try {
      const res = await Settlement.uploadExcel(
        dayjs(fileDate).format('YYYYMM'),
        fileItems
      );

      if (res) {
        const { data, error } = res;
        if (!data && error) {
          window.cerp.dialog.error('업로드 실패', `${error?.message}`);
        } else if (data) {
          window.cerp.toast.success('업로드 성공');
        }
      }
    } catch (error) {
      if (error.response.data.error.message) {
        window.cerp.dialog.error(
          '업로드 실패',
          `${error.response.data.error.message}`
        );
      } else {
        window.cerp.toast.error('업로드 실패');
      }
    } finally {
      setFileItems([]);
      setUploadLoading(false);
    }
  };

  const [checkType, setCheckType] = useState('Y');
  const getMonthCompanyData = async (parameters) => {
    setLoading(true);
    try {
      const data = await Settlement.getCompanies(parameters);
      if (data) {
        setData(data.list);
        setTotalRecords(data.total);
        setSumData(data.sum);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data) {
        window.cerp.toast.error(
          `${error?.response?.data?.error?.code}`,
          `${error?.response?.data?.error?.message}`
        );
      } else {
        if (searchMode) {
          window.cerp.toast.error(`정산내역 검색 실패`);
        } else {
          window.cerp.toast.error(`정산내역 조회 실패`);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const getDayCompany = async (parameters) => {
    try {
      const data = await Settlement.getCustomerCompany(parameters);
      if (data) {
        setData(data?.list);
        setTotalRecords(data.total);
        setSumData(data.sum);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data) {
        window.cerp.toast.error(
          `${error?.response?.data?.error?.code}`,
          `${error?.response?.data?.error?.message}`
        );
      } else {
        window.cerp.toast.error(`정산내역 조회 실패`);
      }
    }
  };

  useEffect(() => {
    if (!!myRoleCode) {
      if (myRoleCode === 'A_MST') {
        if (!searchMode) {
          getMonthCompanyData({
            settlementMonth: dayjs(date).format('YYYYMM'),
            page: lazyParams.page + 1,
            size: lazyParams.rows,
            checkType: checkType,
          });
        } else {
          getMonthCompanyData({
            settlementMonth: dayjs(date).format('YYYYMM'),
            customerCompanyName: !!searchConditions.customerCompanyName
              ? searchConditions.customerCompanyName
              : null,
            page: lazyParams.page + 1,
            size: lazyParams.rows,
            checkType: checkType,
          });
        }
      } else if (myRoleCode === 'C_ADM' || myRoleCode === '6_ADM') {
        getDayCompany({
          settlementMonth: dayjs(date).format('YYYYMM'),
          page: lazyParams.page + 1,
          size: lazyParams.rows,
        });
      }
    }
  }, [myRoleCode, searchMode, lazyParams.page, lazyParams.rows]);

  const resetSearchData = () => {
    setSearchConditions({ ...DEFAULT_SEARCH_CONDITIONS });
    setLazyParams({ ...DEFAULT_LAZY_PARAMS });
    setDate(defaultMonthDate);
  };

  const [result, setResult] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchInfo, setSearchInfo] = useState({
    registrationDate: '',
    distanceDriven: '',
    cnm: '',
    modelYear: '',
  });
  const isTargetEligible = async () => {
    const { modelYear, registrationDate, distanceDriven, cnm } = searchInfo;

    if (modelYear.length !== 4)
      return window.cerp.toast.warn(
        '올바른 형식이 아닙니다.',
        '연식 4자리를 입력해 주세요'
      );
    if (registrationDate.length !== 8)
      return window.cerp.toast.warn(
        '올바른 형식이 아닙니다.',
        '최초등록일 8자리를 입력해 주세요'
      );

    searchInfo.distanceDriven = Number(
      distanceDriven?.includes(',')
        ? distanceDriven.replaceAll(',', '')
        : distanceDriven
    );

    // return console.log(searchInfo);
    if (registrationDate && distanceDriven && cnm) {
      setSearchLoading(true);
      try {
        const data = await Settlement.inquire(searchInfo);
        if (data) {
          setResult(data.repMessage);
        }
      } catch (error) {
        console.log(error);
        window.cerp.toast.error('조회 실패', '다시 시도해 주세요.');
      } finally {
        setSearchLoading(false);
      }
    } else {
      window.cerp.toast.warn('필수값 미입력', '모두 입력해주세요.');
    }
  };

  const [curSafe6Info, setCurSafe6Info] = useState({
    safe6Info: {},
    photoInfo: [],
  });
  const getDetailData = async (dataId) => {
    try {
      const data = await Settlement.getCompanyDetail(dataId);
      if (data) {
        console.log(data);
        if (!data.photoInfo.legth) {
          setCurSafe6Info(data);
        } else {
          setCurSafe6Info(data);
          setShowModal({ ...showModal, detail: true });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInput = (e) => {
    const { id, value } = e.target;
    setSearchInfo({ ...searchInfo, [id]: value });
  };

  const initSearch = () => {
    setSearchInfo({
      registrationDate: '',
      distanceDriven: '',
      cnm: '',
      modelYear: '',
    });
    setResult('');
  };

  const uploadPhoto = async () => {
    try {
      const list = [];
      fileItems.map((el) =>
        list.push({ documentCode: el.documentCode, state: el.state })
      );
      const fileData = {
        dataId: curSafe6Info?.safe6Info?.dataId, // int
        fileData: list,
      };
      const files = [];
      fileItems.map((el) => files.push(el.file));
      // return console.log(fileData, files);
      const data = await Settlement.registerPhoto(fileData, files);
      if (data) {
        console.log(data);
      }
    } catch (error) {
      console.log(error);
      window.cerp.toast.error('업로드 실패', '다시 시도해주세요.');
    }
  };

  const [showSmsModal, setShowSmsModal] = useState(false);
  const [rowClick, setRowClick] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerInfo, setCustomerInfo] = useState(null);
  const [smsLoading, setSmsLoading] = useState(false);
  const [smsData, setSmsData] = useState({
    msgType: '',
    sourceAddr: '',
    destAddr: '',
    subject: null, //opt,
    contents: '',
    fileData: null, //opt
  });

  useEffect(() => {
    if (!!myShopMainPhone) {
      setSmsData({ ...smsData, sourceAddr: myShopMainPhone });
    }
  }, [myShopMainPhone]);

  //ANCHOR - post sms
  const postSms = async () => {
    setSmsLoading(true);
    const type = 'lms';

    const postData = {
      msgType: type,
      sourceAddr: smsData.sourceAddr,
      destAddr: smsData.destAddr.replaceAll('-', ''),
      subject: null, //opt,
      contents: smsData.contents,
      msgSubType: null,
      fileData: null, //opt
      url: smsData.url ?? null, // opt
      msgAuthType: 'CARMON',
    };
    // return console.log(postData);
    try {
      const data = await Claim.postSMS(postData);

      if (data) {
        window.cerp.toast.info('메세지 전송이 완료되었습니다.');
        setShowSmsModal(false);
      }
    } catch (error) {
      setShowSmsModal(false);
      console.log(error);
      window.cerp.toast.warn(error?.response?.data.error?.message);
    }
    setSmsLoading(false);
  };

  const [isConfirm, setIsConFirm] = useState(false);
  const getCustomerDeatil = async (id) => {
    try {
      const data = await customerCompanyService.getData(id);
      if (data) {
        // console.log(data.data);
        setCustomerInfo(data.data);
        setShowSmsModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!!selectedCustomer && !!isConfirm) {
      getCustomerDeatil(selectedCustomer.customerComapnyId);
    }
  }, [selectedCustomer, isConfirm]);

  return (
    <div className="grid">
      <div className="col-12">
        {(() => {
          switch (myInfoLoadable.state) {
            case 'hasValue':
              return (
                <>
                  {myRoleCode === '6_ADM' && (
                    <div className="card">
                      <Panel header="대상차량 조회">
                        <div className="flex gap-3 flex-wrap">
                          <div className="flex flex-column gap-2">
                            <div>
                              <i className="pi pi-check mr-1 text-red-400" />
                              <label htmlFor="cnm">차량명</label>
                            </div>
                            <InputText
                              id="cnm"
                              onChange={(e) => handleInput(e)}
                              onKeyDown={(e) =>
                                e.key === 'Enter' ? isTargetEligible() : null
                              }
                              value={searchInfo.cnm}
                            />
                          </div>
                          <div className="flex flex-column gap-2">
                            <div>
                              <i className="pi pi-check mr-1 text-red-400" />
                              <label htmlFor="modelYear">연식</label>
                            </div>
                            <InputText
                              id="modelYear"
                              onChange={(e) => handleInput(e)}
                              keyfilter={'int'}
                              value={CommonUtil.Formatter.modelYear(
                                searchInfo.modelYear
                              )}
                              onKeyDown={(e) =>
                                e.key === 'Enter' ? isTargetEligible() : null
                              }
                              placeholder="연도 4자리"
                            />
                          </div>
                          <div className="flex flex-column gap-2">
                            <div>
                              <i className="pi pi-check mr-1 text-red-400" />
                              <label htmlFor="registrationDate">
                                최초 등록일
                              </label>
                            </div>
                            <InputText
                              id="registrationDate"
                              onChange={(e) => handleInput(e)}
                              value={searchInfo.registrationDate}
                              placeholder="연월일 8자리"
                              keyfilter={'int'}
                              onKeyDown={(e) =>
                                e.key === 'Enter' ? isTargetEligible() : null
                              }
                            />
                          </div>
                          <div className="flex flex-column gap-2">
                            <div>
                              <i className="pi pi-check mr-1 text-red-400" />
                              <label htmlFor="distanceDriven">
                                주행거리 (Km)
                              </label>
                            </div>
                            <InputText
                              id="distanceDriven"
                              onChange={(e) => handleInput(e)}
                              keyfilter="int"
                              value={searchInfo.distanceDriven}
                              onKeyDown={(e) =>
                                e.key === 'Enter' ? isTargetEligible() : null
                              }
                            />
                          </div>
                          <div className="flex align-items-end gap-3">
                            <Button.Default
                              type="button"
                              label="차량 조회"
                              icon="pi pi-check-circle"
                              loading={searchLoading}
                              onClick={() => isTargetEligible()}
                            />
                            <Button.Default
                              type="button"
                              label="초기화"
                              icon="pi pi-replay"
                              className="p-button-outlined"
                              loading={searchLoading}
                              onClick={() => initSearch()}
                            />
                          </div>
                        </div>

                        <div className="p-inputgroup  mt-4 w-12">
                          <span className="p-inputgroup-addon w-2">결과</span>
                          <InputText
                            value={result}
                            readOnly
                            className={`${
                              result?.includes('아닙니다')
                                ? 'text-red-500'
                                : 'text-blue-500'
                            } font-bold`}
                          />
                        </div>
                      </Panel>
                    </div>
                  )}
                  <SearchMenu
                    onInit={() => {
                      resetSearchData();
                      setSearchMode(null);
                    }}
                    onSearch={() => setSearchMode(searchMode + 1)}
                  >
                    <div className="field col-12 md:col-3 lg:col-3">
                      <div className="mb-2 font-bold">조회기간</div>
                      <Calendar
                        showIcon
                        id="searchMonth"
                        value={date}
                        onChange={(e) => setDate(e.value)}
                        view="month"
                        dateFormat="yy년 mm월"
                        className="w-full"
                      />
                    </div>

                    {myRoleCode === 'A_MST' && (
                      <>
                        <div className="field col-12 sm:col-6 md:col-3 lg:col-3">
                          <div className="font-bold mb-2">Safe6 대상여부</div>
                          <Dropdown
                            id="checkType"
                            className="w-full"
                            options={[
                              { label: '대상', value: 'Y' },
                              { label: '비대상', value: 'N' },
                              { label: '비대상 요청', value: 'Z' },
                            ]}
                            value={checkType}
                            onChange={(e) => setCheckType(e.value)}
                          />
                        </div>
                        <div className="field col-12 sm:col-6 md:col-3 lg:col-3">
                          <label
                            className="font-bold"
                            htmlFor="customerCompanyName"
                          >
                            매매상사
                          </label>
                          <InputText
                            id="customerCompanyName"
                            className="w-full"
                            placeholder="상사명으로 검색"
                            value={searchConditions.customerCompanyName}
                            onChange={({ target: { value } }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                customerCompanyName: value,
                              }));
                            }}
                            onKeyDown={(e) =>
                              e.key === 'Enter'
                                ? setSearchMode(searchMode + 1)
                                : null
                            }
                          />
                        </div>
                      </>
                    )}
                  </SearchMenu>

                  {myRoleCode === 'A_MST' && (
                    <div className="card flex flex-auto flex-wrap align-items-center justify-content-between">
                      {/* <Button.Default
                        type="button"
                        label="문자전송"
                        className="p-button-outlined"
                        icon="pi pi-envelope"
                        onClick={() => setIsConFirm(true)}
                      /> */}
                      <div className="flex gap-2">
                        <Button.Default
                          type="button"
                          label="매도데이터 업로드"
                          icon="pi pi-upload"
                          className=" p-button-outlined"
                          loading={uploadLoading}
                          onClick={() =>
                            setShowModal({ ...showModal, fileUpload: true })
                          }
                        />
                        <Button.Default
                          label="엑셀 다운로드"
                          className="p-button-outlined"
                          icon="pi pi-file-excel"
                          disabled={exporting || !data?.length}
                          loading={exporting}
                          onClick={() =>
                            myRoleCode === 'A_MST'
                              ? exportToExcel('association')
                              : exportToExcel('company')
                          }
                        />
                      </div>
                    </div>
                  )}

                  <div className="card">
                    {myRoleCode === '6_ADM' ? (
                      <DataTable
                        // loading={isLoading}
                        value={data}
                        lazy
                        rows={lazyParams.rows}
                        first={lazyParams.first}
                        totalRecords={totalRecords}
                        paginator
                        responsiveLayout="scroll"
                        emptyMessage="데이터가 없습니다."
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="전체 {totalRecords}건 중 {first} ~ {last}"
                        rowsPerPageOptions={COUNT_PER_PAGE}
                        resizableColumns
                        showGridlines
                        onPage={(event) => {
                          setLazyParams(event);
                        }}
                        dataKey="dataId"
                      >
                        <Column
                          header="순번"
                          align={'center'}
                          body={(rowData, column) => (
                            <span>{column.rowIndex + 1}</span>
                          )}
                        />
                        <Column
                          header="비대상요청"
                          align={'center'}
                          body={({ dataId }) => (
                            <div
                              className="border-1 border-round-sm cursor-pointer border-primary py-1 text-primary"
                              onClick={() => {
                                setShowModal({
                                  ...showModal,
                                  photoUpload: true,
                                });
                                getDetailData(dataId);
                              }}
                            >
                              등록
                            </div>
                          )}
                        />

                        <Column
                          align={'center'}
                          header="성능번호"
                          body={({ statementNumber, dataId }) => (
                            <span
                            // className="text-primary cursor-pointer"
                            // onClick={() => {
                            //   getDetailData(dataId);
                            //   setShowModal({
                            //     ...showModal,
                            //     detail: true,
                            //   });
                            // }}
                            >
                              {statementNumber}
                            </span>
                          )}
                        />
                        <Column
                          align={'center'}
                          field="sellDate"
                          header="매도일"
                          body={({ sellDate }) => (
                            <span>
                              {dayjs(sellDate, 'YYYYMMDD').format('YYYY-MM-DD')}
                            </span>
                          )}
                        />
                        <Column
                          align={'center'}
                          header="성능일"
                          body={({ checkDate }) => (
                            <span>
                              {dayjs(checkDate, 'YYYYMMDD').format(
                                'YYYY-MM-DD'
                              )}
                            </span>
                          )}
                        />
                        <Column
                          align={'center'}
                          field="licensePlateNumber"
                          header="차량번호"
                          body={({ licensePlateNumber }) => (
                            <span>{licensePlateNumber}</span>
                          )}
                        />
                        <Column
                          align={'center'}
                          field="producingCountry"
                          header="국산/수입"
                          body={({ producingCountry }) => (
                            <div>
                              {producingCountry === 'F' ? '수입' : '국산'}
                            </div>
                          )}
                        />
                        <Column
                          align={'center'}
                          field="vin"
                          header="차대번호"
                          body={({ vin }) => <div>{vin}</div>}
                        />
                        <Column
                          align={'center'}
                          field="cnm"
                          header="차량명"
                          body={({ cnm }) => <div>{cnm}</div>}
                        />
                        <Column
                          align={'center'}
                          header="주행거리"
                          body={({ distanceDriven }) => (
                            <span>{distanceDriven}</span>
                          )}
                        />
                        <Column
                          align={'center'}
                          field="fuelName"
                          header="연료"
                          body={({ fuelName }) => <span>{fuelName}</span>}
                        />
                        <Column
                          align={'center'}
                          field="modelYear"
                          header="연식"
                          body={({ modelYear }) => <span>{modelYear}</span>}
                        />
                        <Column
                          align={'center'}
                          field="transmissionName"
                          header="변속기"
                          body={({ transmissionName }) => (
                            <span>{transmissionName}</span>
                          )}
                        />
                        <Column
                          align={'center'}
                          header="최초등록일"
                          body={({ registrationDate }) => (
                            <span>
                              {dayjs(registrationDate, 'YYYYMMDD').format(
                                'YYYY-MM-DD'
                              )}
                            </span>
                          )}
                        />
                      </DataTable>
                    ) : (
                      <DataTable
                        selectionMode={rowClick ? null : 'radiobutton'}
                        selection={selectedCustomer}
                        onSelectionChange={(e) => setSelectedCustomer(e.value)}
                        loading={loading}
                        value={data}
                        lazy
                        rows={lazyParams.rows}
                        first={lazyParams.first}
                        totalRecords={totalRecords}
                        paginator
                        responsiveLayout="scroll"
                        emptyMessage="데이터가 없습니다."
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="전체 {totalRecords}건 중 {first} ~ {last}"
                        rowsPerPageOptions={COUNT_PER_PAGE}
                        resizableColumns
                        showGridlines
                        onPage={(event) => {
                          setLazyParams(event);
                        }}
                        dataKey="customerComapnyId"
                        footerColumnGroup={
                          <ColumnGroup>
                            <Row>
                              <Column
                                colSpan={3}
                                footerClassName="text-right bg-yellow-200"
                                footer="전체 합계"
                              />
                              <Column
                                align={'center'}
                                footerClassName="bg-yellow-200"
                                footer={() =>
                                  sumData
                                    ? priceFormatter.format(
                                        sumData?.totalCountDomestic
                                      )
                                    : 0
                                }
                              />
                              <Column
                                align={'center'}
                                footerClassName="bg-yellow-200"
                                footer={() =>
                                  sumData
                                    ? priceFormatter.format(
                                        sumData?.totalCountForeign
                                      )
                                    : 0
                                }
                              />
                              <Column
                                footerClassName="text-right bg-yellow-200"
                                footer={() =>
                                  sumData
                                    ? priceFormatter.format(
                                        sumData?.totalSumDomestic
                                      )
                                    : 0
                                }
                              />
                              <Column
                                footerClassName="text-right bg-yellow-200"
                                footer={() =>
                                  sumData
                                    ? priceFormatter.format(
                                        sumData?.totalSumForeign
                                      )
                                    : 0
                                }
                              />
                              <Column
                                footerClassName="text-right bg-yellow-200"
                                footer={() =>
                                  sumData
                                    ? priceFormatter.format(
                                        Number(sumData?.totalSumForeign) +
                                          Number(sumData?.totalSumDomestic)
                                      )
                                    : 0
                                }
                              />
                            </Row>
                          </ColumnGroup>
                        }
                      >
                        {myRoleCode === 'A_MST' && (
                          <Column
                            header="선택"
                            selectionMode="single"
                            headerStyle={{ width: '3rem' }}
                          />
                        )}

                        <Column
                          header="순번"
                          align={'center'}
                          body={(rowData, column) => (
                            <span>{column.rowIndex + 1}</span>
                          )}
                        />
                        {myRoleCode === 'A_MST' ? (
                          <Column
                            field="customerCompanyName"
                            header="소속상사"
                            body={({
                              customerCompanyName,
                              customerComapnyId,
                            }) => (
                              <span
                                className="text-primary cursor-pointer"
                                onClick={() => {
                                  setCompanyId(customerComapnyId);
                                  setCompanyName(customerCompanyName);
                                  setShowDetail(true);
                                }}
                              >
                                {customerCompanyName}
                              </span>
                            )}
                          />
                        ) : (
                          <Column
                            header="매도일"
                            body={({ sellDate }) => (
                              <span
                                className="text-primary cursor-pointer"
                                onClick={() => {
                                  setShowDetail(true);
                                  setCurDay(sellDate);
                                }}
                              >
                                {dayjs(sellDate, 'YYYYMMDD').format(
                                  'YYYY-MM-DD'
                                )}
                              </span>
                            )}
                          />
                        )}
                        <Column
                          align={'center'}
                          field="countDomestic"
                          header="국산차량 수"
                          body={({ countDomestic }) => (
                            <div>{countDomestic}</div>
                          )}
                        />
                        <Column
                          align={'center'}
                          field="countForeign"
                          header="수입차량 수"
                          body={({ countForeign }) => <div>{countForeign}</div>}
                        />
                        <Column
                          align={'right'}
                          field="sumDomestic"
                          header="국산차량 금액"
                          body={({ sumDomestic }) => (
                            <span>{priceFormatter.format(sumDomestic)}</span>
                          )}
                        />
                        <Column
                          align={'right'}
                          field="sumForeign"
                          header="수입차량 금액"
                          body={({ sumForeign }) => (
                            <span>{priceFormatter.format(sumForeign)}</span>
                          )}
                        />
                        <Column
                          align={'right'}
                          field="sumTotal"
                          header="총합계 금액"
                          body={({ sumDomestic, sumForeign }) => (
                            <span>
                              {priceFormatter.format(
                                Number(sumDomestic) + Number(sumForeign)
                              )}
                            </span>
                          )}
                        />
                      </DataTable>
                    )}
                  </div>

                  {/* //NOTE - 월별 매도내역 */}
                  {showDetail && (
                    <SafePaymentDetail
                      visible
                      onHide={() => {
                        setShowDetail(false);
                      }}
                      date={date}
                      companyId={companyId}
                      companyName={companyName}
                      loading={loading}
                      day={curDay}
                    />
                  )}

                  {showModal.photoUpload && (
                    <UploadPhotoPopup
                      onHide={() => {
                        setFileItems([]);
                        setShowModal({ ...showModal, photoUpload: false });
                      }}
                      setFileItems={setFileItems}
                      fileItems={fileItems}
                      loading={uploadLoading}
                      curSafe6Info={curSafe6Info.safe6Info}
                      setFileDate={setFileDate}
                      uploadPhoto={uploadPhoto}
                    />
                  )}
                  {showModal.fileUpload && (
                    <ImagesPopup
                      onHide={() => {
                        setFileItems([]);
                        setShowModal({ ...showModal, fileUpload: false });
                      }}
                      setFileItems={setFileItems}
                      fileItems={fileItems}
                      loading={uploadLoading}
                      fileDate={fileDate}
                      setFileDate={setFileDate}
                      uploadExcelData={uploadExcelData}
                    />
                  )}
                  {showModal.detail && curSafe6Info && (
                    <Modal.Default
                      onHide={() =>
                        setShowModal({ ...showModal, detail: false })
                      }
                      header={`상세정보`}
                    >
                      <DataTable
                        value={[curSafe6Info.safe6Info]}
                        dataKey="dataId"
                      >
                        <Column field="statementNumber" header="성능번호" />
                        <Column field="licensePlateNumber" header="차량번호" />
                        <Column field="insuranceName" header="보험사" />
                        <Column field="insurancePrice" header="보험료" />
                        <Column field="insurancePriceVat" header="보험료 VAT" />
                      </DataTable>

                      <div className="mt-4 mb-2 flex align-items-center text-lg">
                        <i className="pi pi-file mr-1" />
                        증빙자료
                      </div>
                      {!!curSafe6Info.photoInfo.length ? (
                        curSafe6Info.photoInfo.map((item, idx) => (
                          <Image
                            key={idx}
                            src={`${process.env.REACT_APP_S3_BASE_URL}${item.filePath}`}
                            alt={item.originalFileName}
                            preview
                            imageClassName="border-round border-1 border-200"
                            imageStyle={{
                              width: 44,
                              height: 44,
                            }}
                          />
                        ))
                      ) : (
                        <div>- 첨부된 파일이 없습니다.</div>
                      )}
                    </Modal.Default>
                  )}

                  {showSmsModal && (
                    <SendSMS
                      showSmsType="SAFE6_CUSTOMER"
                      smsData={smsData}
                      setSmsData={setSmsData}
                      customerInfo={customerInfo}
                      onHide={() => {
                        setIsConFirm(false);
                        setShowSmsModal(!showSmsModal);
                      }}
                      postSms={postSms}
                    />
                  )}
                </>
              );

            case 'loading':
              return <ProgressBar mode="indeterminate" />;

            case 'hasError':
              return null;

            default:
          }
        })()}
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(SafePaymentList, comparisonFn);

const UploadPhotoPopup = ({
  onHide,
  setFileItems,
  loading,
  fileItems,
  curSafe6Info,
  setFileDate,
  uploadPhoto,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    // maxSize: 8 * 1024 * 1024,
    accept: {
      'image/*': [],
    },
    onDrop: async (acceptedFiles) => {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      };

      const compressedImages = await compressImages(acceptedFiles, options);

      compressedImages.forEach((file) => {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });

      const partCodeAttachedFiles = _.reduce(
        compressedImages,
        (res, v) => {
          res.push({ documentCode: '6_NONTARGET', file: v, state: 'I' });
          return res;
        },
        []
      );

      setFileItems((ps) => [...ps, ...partCodeAttachedFiles]);
    },
  });

  return (
    <>
      <Modal.Default
        header={`Safe6 비대상 증빙자료 업로드`}
        onHide={onHide}
        widthClass="30rem"
      >
        <BlockUI
          template={
            loading && (
              <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
            )
          }
          blocked={loading}
        >
          <div className="flex flex-column">
            {!!fileItems?.length ? (
              <div className="p-2">
                {fileItems.map((item, idx) => {
                  const { state = FileState.Loaded } = item;
                  if (state !== FileState.Deleted) {
                    return (
                      <div
                        key={`FILE_${idx}`}
                        className="grid border-1 border-300 border-round-sm mt-2"
                      >
                        <div className="col-12 sm:col-9">
                          <div className="flex flex-auto align-items-center justify-content-start">
                            {_.has(item, 'photoId') ? (
                              <>
                                <Image
                                  src={`${process.env.REACT_APP_S3_BASE_URL}${item.filePath}`}
                                  alt={item.originalFileName}
                                  preview
                                  imageClassName="border-round border-1 border-200"
                                  imageStyle={{
                                    width: 44,
                                    height: 44,
                                  }}
                                />
                                <div className="mx-3 flex flex-column align-items-start justify-content-center gap-1">
                                  <span>{item.originalFileName}</span>
                                  <Badge
                                    value={`${filesize(item.fileSize, {
                                      round: 1,
                                      standard: 'jedec',
                                    })}`}
                                    severity="info"
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <Image
                                  src={item.file.preview}
                                  alt={item.file.name}
                                  preview
                                  imageClassName="border-round border-1 border-200"
                                  imageStyle={{
                                    width: 44,
                                    height: 44,
                                  }}
                                />
                                <div className="mx-3 flex flex-column align-items-start justify-content-center gap-1">
                                  <span>{item.file.name}</span>
                                  <Badge
                                    value={`${filesize(item.file.size, {
                                      round: 1,
                                      standard: 'jedec',
                                    })}`}
                                    severity="info"
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="col-12 sm:col-3 text-right">
                          <Button.Default
                            type="button"
                            icon="pi pi-trash"
                            className="p-button-danger p-button-outlined"
                            onClick={() => {
                              const clonedFiles = [...fileItems];
                              const clonedFile = clonedFiles[idx];

                              if (_.has(clonedFile, 'photoId')) {
                                clonedFiles[idx] = {
                                  ...clonedFile,
                                  state: FileState.Deleted,
                                };
                              } else {
                                clonedFiles.splice(idx, 1);
                              }

                              setFileItems(clonedFiles);
                            }}
                          />
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}

                <div className="flex align-items-center gap-6 justify-content-center mt-4">
                  <Button.Default
                    type="button"
                    label="취소"
                    icon="pi pi-times"
                    className=" p-button-outlined"
                    loading={loading}
                    onClick={() => {
                      setFileItems([]);
                      onHide(true);
                    }}
                  />
                  <Button.Default
                    type="button"
                    label="확인"
                    icon="pi pi-check"
                    className=" p-button"
                    loading={loading}
                    onClick={() => {
                      uploadPhoto();
                      onHide(true);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="col">
                <div
                  {...getRootProps({
                    className:
                      'p-2 lg:p-3 bg-gray-50 border-round border-1 border-400 border-dashed cursor-pointer',
                    style: { wordBreak: 'keep-all' },
                  })}
                >
                  <input {...getInputProps()} />
                  <div className="flex flex-auto flex-column align-items-center justify-content-center gap-2">
                    <i
                      className="pi pi-arrow-circle-down"
                      style={{ fontSize: '1.5rem' }}
                    />
                    파일을 끌어다 놓거나 클릭하여 등록해주세요.
                  </div>
                </div>
              </div>
            )}
          </div>
        </BlockUI>
      </Modal.Default>
    </>
  );
};
const ImagesPopup = ({
  onHide,
  setFileItems,
  loading,
  fileItems,
  fileDate,
  setFileDate,
  uploadExcelData,
}) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: false,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xls',
        '.xlsx',
      ],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
    },
    onDrop: (acceptedFiles) => {
      // console.log(acceptedFiles);
      setFileItems([...acceptedFiles]);
    },
  });
  return (
    <>
      <Modal.Default
        header={`매도데이터 파일 업로드`}
        onHide={onHide}
        widthClass="30rem"
      >
        <BlockUI
          template={
            loading && (
              <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
            )
          }
          blocked={loading}
        >
          <div className="flex flex-column">
            {!!fileItems?.length ? (
              <div className="p-2">
                <Panel header="파일정보">
                  <div>- 이름 : {fileItems[0].name}</div>
                </Panel>
                <Panel header="등록기간" className="mt-2">
                  <div className="flex gap-2 align-items-center">
                    <div>- 월 선택 :</div>
                    <Calendar
                      showIcon
                      id="searchMonth"
                      value={fileDate}
                      onChange={(e) => setFileDate(e.value)}
                      view="month"
                      dateFormat="yy년 mm월"
                    />
                  </div>
                </Panel>
                <div className="flex align-items-center gap-6 justify-content-center mt-4">
                  <Button.Default
                    type="button"
                    label="취소"
                    icon="pi pi-times"
                    className=" p-button-outlined"
                    loading={loading}
                    onClick={() => {
                      setFileItems([]);
                      onHide(true);
                    }}
                  />
                  <Button.Default
                    type="button"
                    label="확인"
                    icon="pi pi-check"
                    className=" p-button"
                    loading={loading}
                    onClick={() => {
                      uploadExcelData();
                      onHide(true);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="col">
                <div
                  {...getRootProps({
                    className:
                      'p-2 lg:p-3 bg-gray-50 border-round border-1 border-400 border-dashed cursor-pointer',
                    style: { wordBreak: 'keep-all' },
                  })}
                >
                  <input {...getInputProps()} />
                  <div className="flex flex-auto flex-column align-items-center justify-content-center gap-2">
                    <i
                      className="pi pi-arrow-circle-down"
                      style={{ fontSize: '1.5rem' }}
                    />
                    파일을 끌어다 놓거나 클릭하여 등록해주세요.
                  </div>
                </div>
              </div>
            )}
          </div>
        </BlockUI>
      </Modal.Default>
    </>
  );
};
